<template>
  <v-bottom-navigation grow fixed class="bottom-nav-bar white lighten-1">
    <!-- home -->
    <v-btn class="bottom-home" :to="{ name: 'Home' }">
      <span>{{ $t("bottom.home") }}</span>
      <v-icon>$home</v-icon>
    </v-btn>
    <!-- prodotti -->
    <v-btn @click.prevent.stop="drawerLeft = !drawerLeft">
      <span>{{ $t("bottom.products") }}</span>
      <v-icon>$menu</v-icon>
    </v-btn>
    <!-- carrello -->
    <v-btn @click="handleLogin('/checkout')">
      <v-badge
        :content="totalItems"
        :value="totalItems > 0"
        :key="totalItems"
        transition="bounce"
        color="secondary darken-1 default--text font-weight-bold"
        overlap
        offset-x="20"
        class="d-flex flex-column"
      >
        <v-icon>$cart</v-icon>
        <span>{{ $t("bottom.cart") }}</span>
      </v-badge>
    </v-btn>
    <v-btn @click="handleLogin('/profile/dashboard')">
      <span>{{ $t("bottom.account") }}</span>
      <v-icon>$profile</v-icon>
    </v-btn>
    <!-- ordini -->
    <v-btn @click="handleLogin('/profile/orders')">
      <span>{{ $t("bottom.orders") }}</span>
      <v-icon>$orders</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import login from "~/mixins/login";
import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  mixins: [login],
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    async handleLogin(path) {
      if (this.isAuthenticated) {
        this.$router.push(path);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$router.push(path);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.storeDrawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    }
  }
};
</script>
<style lang="scss">
.is-cordova {
  .v-main {
    padding-bottom: calc(160px + env(safe-area-inset-top)) !important;
  }
  .bottom-home.v-btn--active {
    color: $text-color;
    opacity: 0.6 !important;
  }
}
.keyboard-open {
  .v-main {
    padding-bottom: calc(80px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios {
  .v-main {
    padding-bottom: calc(210px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios.keyboard-open {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}
</style>
<style scoped lang="scss">
.bottom-nav-bar {
  height: $bottom-navigation-bar-height;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
.platform-ios {
  .bottom-nav-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(90px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 32px !important;
    }
  }
}
.v-bottom-navigation .v-btn {
  min-width: auto !important;
  padding: 0 5px;
  .v-btn__content {
    color: $white !important;
    .v-icon {
      font-size: 22px;
    }
  }
}
.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}
</style>
